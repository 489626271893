define("lodash/_basePick", ["exports", "lodash/_basePickBy", "lodash/hasIn"], function (_exports, _basePickBy, _hasIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The base implementation of `_.pick` without support for individual
   * property identifiers.
   *
   * @private
   * @param {Object} object The source object.
   * @param {string[]} paths The property paths to pick.
   * @returns {Object} Returns the new object.
   */
  function basePick(object, paths) {
    return (0, _basePickBy.default)(object, paths, function (value, path) {
      return (0, _hasIn.default)(object, path);
    });
  }

  var _default = basePick;
  _exports.default = _default;
});