define('ember-intercom-io/services/intercom', ['exports', 'intercom'], function (exports, _intercom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, "user", { email: null, name: null, hash: null, user_id: null });
    },


    api: _intercom.default,

    _userHashProp: Ember.computed('config.userProperties.userHashProp', function () {
      return Ember.get(this, 'user.' + Ember.get(this, 'config.userProperties.userHashProp'));
    }),

    _userIdProp: Ember.computed('config.userProperties.userIdProp', function () {
      return Ember.get(this, 'user.' + Ember.get(this, 'config.userProperties.userIdProp'));
    }),

    _userNameProp: Ember.computed('config.userProperties.nameProp', function () {
      return Ember.get(this, 'user.' + Ember.get(this, 'config.userProperties.nameProp'));
    }),

    _userEmailProp: Ember.computed('config.userProperties.emailProp', function () {
      return Ember.get(this, 'user.' + Ember.get(this, 'config.userProperties.emailProp'));
    }),

    _userCreatedAtProp: Ember.computed('config.userProperties.createdAtProp', function () {
      return Ember.get(this, 'user.' + Ember.get(this, 'config.userProperties.createdAtProp'));
    }),

    user: null,

    _hasUserContext: Ember.computed('user', '_userNameProp', '_userEmailProp', '_userCreatedAtProp', function () {
      return !!Ember.get(this, 'user') && !!Ember.get(this, '_userNameProp') && (!!Ember.get(this, '_userEmailProp') || !!Ember.get(this, "_userIdProp"));
    }),

    _intercomBootConfig: Ember.computed('_hasUserContext', function () {
      var appId = Ember.get(this, 'config.appId');
      (false && !(appId) && Ember.assert('You must supply an "ENV.intercom.appId" in your "config/environment.js" file.', appId));


      var obj = {
        app_id: appId // eslint-disable-line
      };

      if (Ember.get(this, '_hasUserContext')) {
        obj.user_hash = Ember.get(this, '_userHashProp');
        obj.user_id = Ember.get(this, '_userIdProp');
        obj.name = Ember.get(this, '_userNameProp');
        obj.email = Ember.get(this, '_userEmailProp');
        if (Ember.get(this, '_userCreatedAtProp')) {
          // eslint-disable-next-line
          obj.created_at = Ember.get(this, '_userCreatedAtProp');
        }
      }
      return obj;
    }),

    start: function start() {
      var _this = this;

      var bootConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _bootConfig = Ember.merge(Ember.get(this, '_intercomBootConfig'), bootConfig);
      Ember.run.scheduleOnce('afterRender', function () {
        return _this.get('api')('boot', _bootConfig);
      });
    },
    stop: function stop() {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        return _this2.get('api')('shutdown');
      });
    },
    update: function update() {
      var _this3 = this;

      var properties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      Ember.run.scheduleOnce('afterRender', function () {
        return _this3.get('api')('update', properties);
      });
    }
  });
});