define("lodash/attempt", ["exports", "lodash/_apply", "lodash/_baseRest", "lodash/isError"], function (_exports, _apply, _baseRest, _isError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Attempts to invoke `func`, returning either the result or the caught error
   * object. Any additional arguments are provided to `func` when it's invoked.
   *
   * @static
   * @memberOf _
   * @since 3.0.0
   * @category Util
   * @param {Function} func The function to attempt.
   * @param {...*} [args] The arguments to invoke `func` with.
   * @returns {*} Returns the `func` result or error object.
   * @example
   *
   * // Avoid throwing errors for invalid selectors.
   * var elements = _.attempt(function(selector) {
   *   return document.querySelectorAll(selector);
   * }, '>_>');
   *
   * if (_.isError(elements)) {
   *   elements = [];
   * }
   */
  var attempt = (0, _baseRest.default)(function (func, args) {
    try {
      return (0, _apply.default)(func, undefined, args);
    } catch (e) {
      return (0, _isError.default)(e) ? e : new Error(e);
    }
  });
  var _default = attempt;
  _exports.default = _default;
});