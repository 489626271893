define("lodash/_assignMergeValue", ["exports", "lodash/_baseAssignValue", "lodash/eq"], function (_exports, _baseAssignValue, _eq) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This function is like `assignValue` except that it doesn't assign
   * `undefined` values.
   *
   * @private
   * @param {Object} object The object to modify.
   * @param {string} key The key of the property to assign.
   * @param {*} value The value to assign.
   */
  function assignMergeValue(object, key, value) {
    if (value !== undefined && !(0, _eq.default)(object[key], value) || value === undefined && !(key in object)) {
      (0, _baseAssignValue.default)(object, key, value);
    }
  }

  var _default = assignMergeValue;
  _exports.default = _default;
});