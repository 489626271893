define("lodash/_copySymbols", ["exports", "lodash/_copyObject", "lodash/_getSymbols"], function (_exports, _copyObject, _getSymbols) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copies own symbols of `source` to `object`.
   *
   * @private
   * @param {Object} source The object to copy symbols from.
   * @param {Object} [object={}] The object to copy symbols to.
   * @returns {Object} Returns `object`.
   */
  function copySymbols(source, object) {
    return (0, _copyObject.default)(source, (0, _getSymbols.default)(source), object);
  }

  var _default = copySymbols;
  _exports.default = _default;
});