define("ember-gestures/utils/string/strip-whitespace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = stripWhiteSpace;

  function stripWhiteSpace(s) {
    return s.replace(/\s/g, '');
  }
});