define("lodash/_hashClear", ["exports", "lodash/_nativeCreate"], function (_exports, _nativeCreate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Removes all key-value entries from the hash.
   *
   * @private
   * @name clear
   * @memberOf Hash
   */
  function hashClear() {
    this.__data__ = _nativeCreate.default ? (0, _nativeCreate.default)(null) : {};
    this.size = 0;
  }

  var _default = hashClear;
  _exports.default = _default;
});