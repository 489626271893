define("lodash/_arrayShuffle", ["exports", "lodash/_copyArray", "lodash/_shuffleSelf"], function (_exports, _copyArray, _shuffleSelf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A specialized version of `_.shuffle` for arrays.
   *
   * @private
   * @param {Array} array The array to shuffle.
   * @returns {Array} Returns the new shuffled array.
   */
  function arrayShuffle(array) {
    return (0, _shuffleSelf.default)((0, _copyArray.default)(array));
  }

  var _default = arrayShuffle;
  _exports.default = _default;
});