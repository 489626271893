define("ember-composable-helpers/helpers/filter-by", ["exports", "ember-composable-helpers/utils/is-equal"], function (_exports, _isEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function filterBy(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        byPath = _ref2[0],
        value = _ref2[1],
        array = _ref2[2];

    if (!Ember.isArray(array) && Ember.isArray(value)) {
      array = value;
      value = undefined;
    }

    if (Ember.isEmpty(byPath) || Ember.isEmpty(array)) {
      return [];
    }

    var filterFn;

    if (Ember.isPresent(value)) {
      if (typeof value === 'function') {
        filterFn = function filterFn(item) {
          return value(Ember.get(item, byPath));
        };
      } else {
        filterFn = function filterFn(item) {
          return (0, _isEqual.default)(Ember.get(item, byPath), value);
        };
      }
    } else {
      filterFn = function filterFn(item) {
        return !!Ember.get(item, byPath);
      };
    }

    return array.filter(filterFn);
  }

  var _default = Ember.Helper.helper(filterBy);

  _exports.default = _default;
});