define("lodash/_hashHas", ["exports", "lodash/_nativeCreate"], function (_exports, _nativeCreate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** Used for built-in method references. */
  var objectProto = Object.prototype;
  /** Used to check objects for own properties. */

  var hasOwnProperty = objectProto.hasOwnProperty;
  /**
   * Checks if a hash value for `key` exists.
   *
   * @private
   * @name has
   * @memberOf Hash
   * @param {string} key The key of the entry to check.
   * @returns {boolean} Returns `true` if an entry for `key` exists, else `false`.
   */

  function hashHas(key) {
    var data = this.__data__;
    return _nativeCreate.default ? data[key] !== undefined : hasOwnProperty.call(data, key);
  }

  var _default = hashHas;
  _exports.default = _default;
});