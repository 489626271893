define("lodash/math.default", ["exports", "lodash/add", "lodash/ceil", "lodash/divide", "lodash/floor", "lodash/max", "lodash/maxBy", "lodash/mean", "lodash/meanBy", "lodash/min", "lodash/minBy", "lodash/multiply", "lodash/round", "lodash/subtract", "lodash/sum", "lodash/sumBy"], function (_exports, _add, _ceil, _divide, _floor, _max, _maxBy, _mean, _meanBy, _min, _minBy, _multiply, _round, _subtract, _sum, _sumBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    add: _add.default,
    ceil: _ceil.default,
    divide: _divide.default,
    floor: _floor.default,
    max: _max.default,
    maxBy: _maxBy.default,
    mean: _mean.default,
    meanBy: _meanBy.default,
    min: _min.default,
    minBy: _minBy.default,
    multiply: _multiply.default,
    round: _round.default,
    subtract: _subtract.default,
    sum: _sum.default,
    sumBy: _sumBy.default
  };
  _exports.default = _default;
});