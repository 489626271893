define("lodash/_baseRepeat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** Used as references for various `Number` constants. */
  var MAX_SAFE_INTEGER = 9007199254740991;
  /* Built-in method references for those with the same name as other `lodash` methods. */

  var nativeFloor = Math.floor;
  /**
   * The base implementation of `_.repeat` which doesn't coerce arguments.
   *
   * @private
   * @param {string} string The string to repeat.
   * @param {number} n The number of times to repeat the string.
   * @returns {string} Returns the repeated string.
   */

  function baseRepeat(string, n) {
    var result = '';

    if (!string || n < 1 || n > MAX_SAFE_INTEGER) {
      return result;
    } // Leverage the exponentiation by squaring algorithm for a faster repeat.
    // See https://en.wikipedia.org/wiki/Exponentiation_by_squaring for more details.


    do {
      if (n % 2) {
        result += string;
      }

      n = nativeFloor(n / 2);

      if (n) {
        string += string;
      }
    } while (n);

    return result;
  }

  var _default = baseRepeat;
  _exports.default = _default;
});