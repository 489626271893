define("ember-gestures/utils/string/dasherized-to-camel", ["exports", "ember-gestures/utils/string/uncapitalize-word", "ember-gestures/utils/string/dasherized-to-words", "ember-gestures/utils/string/capitalize-words", "ember-gestures/utils/string/strip-whitespace"], function (_exports, _uncapitalizeWord, _dasherizedToWords, _capitalizeWords, _stripWhitespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(s) {
    return (0, _uncapitalizeWord.default)((0, _stripWhitespace.default)((0, _capitalizeWords.default)((0, _dasherizedToWords.default)(s))));
  }
});