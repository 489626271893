define("ember-sortable/templates/components/sortable-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YsV1lwxb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"item\",\"model\"],[[28,\"component\",[\"ember-sortable@sortable-item\"],[[\"direction\",\"registerItem\",\"deregisterItem\",\"setSelectedItem\",\"update\",\"prepare\",\"commit\",\"activateKeyDown\",\"deactivateKeyDown\"],[[24,[\"direction\"]],[28,\"action\",[[23,0,[]],\"registerItem\"],null],[28,\"action\",[[23,0,[]],\"deregisterItem\"],null],[28,\"action\",[[23,0,[]],\"setSelectedItem\"],null],[28,\"action\",[[23,0,[]],\"update\"],null],[28,\"action\",[[23,0,[]],\"prepare\"],null],[28,\"action\",[[23,0,[]],\"commit\"],null],[28,\"action\",[[23,0,[]],\"activateKeyDown\"],null],[28,\"action\",[[23,0,[]],\"deactivateKeyDown\"],null]]]],[24,[\"model\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-sortable/templates/components/sortable-group.hbs"
    }
  });

  _exports.default = _default;
});