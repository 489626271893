define('ember-intercom-io/components/intercom-io', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intercom: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.get(this, 'intercom').start();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.get(this, 'intercom').stop();
    }
  });
});