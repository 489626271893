define("lodash/_createCtor", ["exports", "lodash/_baseCreate", "lodash/isObject"], function (_exports, _baseCreate, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a function that produces an instance of `Ctor` regardless of
   * whether it was invoked as part of a `new` expression or by `call` or `apply`.
   *
   * @private
   * @param {Function} Ctor The constructor to wrap.
   * @returns {Function} Returns the new wrapped function.
   */
  function createCtor(Ctor) {
    return function () {
      // Use a `switch` statement to work with class constructors. See
      // http://ecma-international.org/ecma-262/7.0/#sec-ecmascript-function-objects-call-thisargument-argumentslist
      // for more details.
      var args = arguments;

      switch (args.length) {
        case 0:
          return new Ctor();

        case 1:
          return new Ctor(args[0]);

        case 2:
          return new Ctor(args[0], args[1]);

        case 3:
          return new Ctor(args[0], args[1], args[2]);

        case 4:
          return new Ctor(args[0], args[1], args[2], args[3]);

        case 5:
          return new Ctor(args[0], args[1], args[2], args[3], args[4]);

        case 6:
          return new Ctor(args[0], args[1], args[2], args[3], args[4], args[5]);

        case 7:
          return new Ctor(args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
      }

      var thisBinding = (0, _baseCreate.default)(Ctor.prototype),
          result = Ctor.apply(thisBinding, args); // Mimic the constructor's `return` behavior.
      // See https://es5.github.io/#x13.2.2 for more details.

      return (0, _isObject.default)(result) ? result : thisBinding;
    };
  }

  var _default = createCtor;
  _exports.default = _default;
});