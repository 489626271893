define("lodash/takeRightWhile", ["exports", "lodash/_baseIteratee", "lodash/_baseWhile"], function (_exports, _baseIteratee, _baseWhile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a slice of `array` with elements taken from the end. Elements are
   * taken until `predicate` returns falsey. The predicate is invoked with
   * three arguments: (value, index, array).
   *
   * @static
   * @memberOf _
   * @since 3.0.0
   * @category Array
   * @param {Array} array The array to query.
   * @param {Function} [predicate=_.identity] The function invoked per iteration.
   * @returns {Array} Returns the slice of `array`.
   * @example
   *
   * var users = [
   *   { 'user': 'barney',  'active': true },
   *   { 'user': 'fred',    'active': false },
   *   { 'user': 'pebbles', 'active': false }
   * ];
   *
   * _.takeRightWhile(users, function(o) { return !o.active; });
   * // => objects for ['fred', 'pebbles']
   *
   * // The `_.matches` iteratee shorthand.
   * _.takeRightWhile(users, { 'user': 'pebbles', 'active': false });
   * // => objects for ['pebbles']
   *
   * // The `_.matchesProperty` iteratee shorthand.
   * _.takeRightWhile(users, ['active', false]);
   * // => objects for ['fred', 'pebbles']
   *
   * // The `_.property` iteratee shorthand.
   * _.takeRightWhile(users, 'active');
   * // => []
   */
  function takeRightWhile(array, predicate) {
    return array && array.length ? (0, _baseWhile.default)(array, (0, _baseIteratee.default)(predicate, 3), false, true) : [];
  }

  var _default = takeRightWhile;
  _exports.default = _default;
});