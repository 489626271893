define('ember-cli-sentry/index', ['exports', 'raven'], function (exports, _raven) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(container, config) {
    if (_raven.default.isSetup() === true) {
      return;
    }

    if (Ember.get(config, 'sentry.development') === true) {
      if (Ember.get(config, 'sentry.debug') === true) {
        Ember.Logger.info('`sentry` is configured for development mode.');
      }
      return;
    }

    if (!config.sentry) {
      throw new Error('`sentry` should be configured when not in development mode.');
    }

    container.lookup('service:raven').setup(config);
  }
});