define("ember-gestures/hammer-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    pan: 'pan',
    panstart: 'panStart',
    panmove: 'panMove',
    panend: 'panEnd',
    pancancel: 'panCancel',
    panleft: 'panLeft',
    panright: 'panRight',
    panup: 'panUp',
    pandown: 'panDown',
    pinch: 'pinch',
    pinchstart: 'pinchStart',
    pinchmove: 'pinchMove',
    pinchend: 'pinchEnd',
    pinchcancel: 'pinchCancel',
    pinchin: 'pinchIn',
    pinchout: 'pinchOut',
    press: 'press',
    pressup: 'pressUp',
    rotate: 'rotate',
    rotatestart: 'rotateStart',
    rotatemove: 'rotateMove',
    rotateend: 'rotateEnd',
    rotatecancel: 'rotateCancel',
    swipe: 'swipe',
    swipeleft: 'swipeLeft',
    swiperight: 'swipeRight',
    swipeup: 'swipeUp',
    swipedown: 'swipeDown',
    tap: 'tap'
  };
  _exports.default = _default;
});