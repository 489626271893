define("lodash/_getFuncName", ["exports", "lodash/_realNames"], function (_exports, _realNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** Used for built-in method references. */
  var objectProto = Object.prototype;
  /** Used to check objects for own properties. */

  var hasOwnProperty = objectProto.hasOwnProperty;
  /**
   * Gets the name of `func`.
   *
   * @private
   * @param {Function} func The function to query.
   * @returns {string} Returns the function name.
   */

  function getFuncName(func) {
    var result = func.name + '',
        array = _realNames.default[result],
        length = hasOwnProperty.call(_realNames.default, result) ? array.length : 0;

    while (length--) {
      var data = array[length],
          otherFunc = data.func;

      if (otherFunc == null || otherFunc == func) {
        return data.name;
      }
    }

    return result;
  }

  var _default = getFuncName;
  _exports.default = _default;
});