define('ember-intercom-io/initializers/ember-intercom', ['exports', 'intercom'], function (exports, _intercom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    if (typeof FastBoot === 'undefined') {
      var config = void 0;

      if (application.resolveRegistration) {
        config = application.resolveRegistration('config:environment');
      } else {
        config = application.registry.resolve('config:environment');
      }

      (0, _intercom._setup)(config);
    }
  }

  exports.default = {
    name: 'ember-intercom',
    initialize: initialize
  };
});