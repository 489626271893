define("lodash/mapKeys", ["exports", "lodash/_baseAssignValue", "lodash/_baseForOwn", "lodash/_baseIteratee"], function (_exports, _baseAssignValue, _baseForOwn, _baseIteratee) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The opposite of `_.mapValues`; this method creates an object with the
   * same values as `object` and keys generated by running each own enumerable
   * string keyed property of `object` thru `iteratee`. The iteratee is invoked
   * with three arguments: (value, key, object).
   *
   * @static
   * @memberOf _
   * @since 3.8.0
   * @category Object
   * @param {Object} object The object to iterate over.
   * @param {Function} [iteratee=_.identity] The function invoked per iteration.
   * @returns {Object} Returns the new mapped object.
   * @see _.mapValues
   * @example
   *
   * _.mapKeys({ 'a': 1, 'b': 2 }, function(value, key) {
   *   return key + value;
   * });
   * // => { 'a1': 1, 'b2': 2 }
   */
  function mapKeys(object, iteratee) {
    var result = {};
    iteratee = (0, _baseIteratee.default)(iteratee, 3);
    (0, _baseForOwn.default)(object, function (value, key, object) {
      (0, _baseAssignValue.default)(result, iteratee(value, key, object), value);
    });
    return result;
  }

  var _default = mapKeys;
  _exports.default = _default;
});