define("lodash/collection.default", ["exports", "lodash/countBy", "lodash/each", "lodash/eachRight", "lodash/every", "lodash/filter", "lodash/find", "lodash/findLast", "lodash/flatMap", "lodash/flatMapDeep", "lodash/flatMapDepth", "lodash/forEach", "lodash/forEachRight", "lodash/groupBy", "lodash/includes", "lodash/invokeMap", "lodash/keyBy", "lodash/map", "lodash/orderBy", "lodash/partition", "lodash/reduce", "lodash/reduceRight", "lodash/reject", "lodash/sample", "lodash/sampleSize", "lodash/shuffle", "lodash/size", "lodash/some", "lodash/sortBy"], function (_exports, _countBy, _each, _eachRight, _every, _filter, _find, _findLast, _flatMap, _flatMapDeep, _flatMapDepth, _forEach, _forEachRight, _groupBy, _includes, _invokeMap, _keyBy, _map, _orderBy, _partition, _reduce, _reduceRight, _reject, _sample, _sampleSize, _shuffle, _size, _some, _sortBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    countBy: _countBy.default,
    each: _each.default,
    eachRight: _eachRight.default,
    every: _every.default,
    filter: _filter.default,
    find: _find.default,
    findLast: _findLast.default,
    flatMap: _flatMap.default,
    flatMapDeep: _flatMapDeep.default,
    flatMapDepth: _flatMapDepth.default,
    forEach: _forEach.default,
    forEachRight: _forEachRight.default,
    groupBy: _groupBy.default,
    includes: _includes.default,
    invokeMap: _invokeMap.default,
    keyBy: _keyBy.default,
    map: _map.default,
    orderBy: _orderBy.default,
    partition: _partition.default,
    reduce: _reduce.default,
    reduceRight: _reduceRight.default,
    reject: _reject.default,
    sample: _sample.default,
    sampleSize: _sampleSize.default,
    shuffle: _shuffle.default,
    size: _size.default,
    some: _some.default,
    sortBy: _sortBy.default
  };
  _exports.default = _default;
});