define('ember-data-url-templates/utils/flatten-query-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = flattenQueryParams;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  // This was inspired by https://stackoverflow.com/a/34514143/223519
  // and ultimately reconfigured by @basz
  // https://github.com/amiel/ember-data-url-templates/issues/17#issuecomment-379232452

  function flattenQueryParams(arr) {
    var newObj = {};
    dive('', arr, newObj);
    return newObj;
  }

  function dive(currentKey, into, target) {
    for (var i in into) {
      if (into.hasOwnProperty(i)) {
        var newKey = i;
        var newVal = into[i];

        if (currentKey.length > 0) {
          newKey = currentKey + '[' + i + ']';
        }

        if ((typeof newVal === 'undefined' ? 'undefined' : _typeof(newVal)) === 'object') {
          dive(newKey, newVal, target);
        } else {
          target[newKey] = newVal;
        }
      }
    }
  }
});