define("lodash/sortBy", ["exports", "lodash/_baseFlatten", "lodash/_baseOrderBy", "lodash/_baseRest", "lodash/_isIterateeCall"], function (_exports, _baseFlatten, _baseOrderBy, _baseRest, _isIterateeCall) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates an array of elements, sorted in ascending order by the results of
   * running each element in a collection thru each iteratee. This method
   * performs a stable sort, that is, it preserves the original sort order of
   * equal elements. The iteratees are invoked with one argument: (value).
   *
   * @static
   * @memberOf _
   * @since 0.1.0
   * @category Collection
   * @param {Array|Object} collection The collection to iterate over.
   * @param {...(Function|Function[])} [iteratees=[_.identity]]
   *  The iteratees to sort by.
   * @returns {Array} Returns the new sorted array.
   * @example
   *
   * var users = [
   *   { 'user': 'fred',   'age': 48 },
   *   { 'user': 'barney', 'age': 36 },
   *   { 'user': 'fred',   'age': 40 },
   *   { 'user': 'barney', 'age': 34 }
   * ];
   *
   * _.sortBy(users, [function(o) { return o.user; }]);
   * // => objects for [['barney', 36], ['barney', 34], ['fred', 48], ['fred', 40]]
   *
   * _.sortBy(users, ['user', 'age']);
   * // => objects for [['barney', 34], ['barney', 36], ['fred', 40], ['fred', 48]]
   */
  var sortBy = (0, _baseRest.default)(function (collection, iteratees) {
    if (collection == null) {
      return [];
    }

    var length = iteratees.length;

    if (length > 1 && (0, _isIterateeCall.default)(collection, iteratees[0], iteratees[1])) {
      iteratees = [];
    } else if (length > 2 && (0, _isIterateeCall.default)(iteratees[0], iteratees[1], iteratees[2])) {
      iteratees = [iteratees[0]];
    }

    return (0, _baseOrderBy.default)(collection, (0, _baseFlatten.default)(iteratees, 1), []);
  });
  var _default = sortBy;
  _exports.default = _default;
});