define("ember-sortable/utils/keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDownArrowKey = _exports.isRightArrowKey = _exports.isUpArrowKey = _exports.isLeftArrowKey = _exports.isSpaceKey = _exports.isEscapeKey = _exports.isEnterKey = _exports.ARROW_KEY_CODES = _exports.SPACE_KEY_CODE = _exports.ESCAPE_KEY_CODE = _exports.ENTER_KEY_CODE = void 0;
  var ENTER_KEY = 'Enter';
  var ESCAPE_KEY = 'Escape';
  var SPACE_KEY = 'Space';
  var ARROW_KEYS = {
    LEFT: 'ArrowLeft',
    UP: 'ArrowUp',
    RIGHT: 'ArrowRight',
    DOWN: 'ArrowDown'
  };
  var ENTER_KEY_CODE = 13;
  _exports.ENTER_KEY_CODE = ENTER_KEY_CODE;
  var ESCAPE_KEY_CODE = 27;
  _exports.ESCAPE_KEY_CODE = ESCAPE_KEY_CODE;
  var SPACE_KEY_CODE = 32;
  _exports.SPACE_KEY_CODE = SPACE_KEY_CODE;
  var ARROW_KEY_CODES = {
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
  };
  _exports.ARROW_KEY_CODES = ARROW_KEY_CODES;

  function createKeyTest(key, keyCode) {
    return function isKey(event) {
      return event.key === key || event.keyCode === keyCode;
    };
  }

  var isEnterKey = createKeyTest(ENTER_KEY, ENTER_KEY_CODE);
  _exports.isEnterKey = isEnterKey;
  var isEscapeKey = createKeyTest(ESCAPE_KEY, ESCAPE_KEY_CODE);
  _exports.isEscapeKey = isEscapeKey;
  var isSpaceKey = createKeyTest(SPACE_KEY, SPACE_KEY_CODE);
  _exports.isSpaceKey = isSpaceKey;
  var isLeftArrowKey = createKeyTest(ARROW_KEYS.LEFT, ARROW_KEY_CODES.LEFT);
  _exports.isLeftArrowKey = isLeftArrowKey;
  var isUpArrowKey = createKeyTest(ARROW_KEYS.UP, ARROW_KEY_CODES.UP);
  _exports.isUpArrowKey = isUpArrowKey;
  var isRightArrowKey = createKeyTest(ARROW_KEYS.RIGHT, ARROW_KEY_CODES.RIGHT);
  _exports.isRightArrowKey = isRightArrowKey;
  var isDownArrowKey = createKeyTest(ARROW_KEYS.DOWN, ARROW_KEY_CODES.DOWN);
  _exports.isDownArrowKey = isDownArrowKey;
});