define("ember-component-css/utils/init-route-styles", ["exports", "ember-component-css/pod-names"], function (_exports, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initRouteStyles;

  function initRouteStyles(owner, routeNames) {
    var classes = [];

    for (var i = 0; i < routeNames.length; i++) {
      var routeName = routeNames[i];

      var styleNamespace = _podNames.default[routeName.replace(/\./g, '/')];

      if (styleNamespace) {
        classes.push(styleNamespace);

        var _controller = owner.lookup("controller:".concat(routeName));

        if (_controller) {
          _controller.set('styleNamespace', styleNamespace);
        }
      }
    }

    var controller = owner.lookup('controller:application');

    if (controller) {
      controller.set('routeStyleNamespaceClassSet', classes.join(' '));
    }
  }
});