define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "checkin-field-image": "__93c31",
    "config-checkin-time": "__db4e6",
    "modal-confirm": "__5c4db",
    "set-frequency": "__68c1e",
    "x-clear": "__f3776"
  };
  _exports.default = _default;
});