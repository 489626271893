define('ember-data-url-templates/mixins/url-templates-serializer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LINK_PREFIX = exports.LINK_PREFIX = 'urlTemplate:';

  exports.default = Ember.Mixin.create({
    normalize: function normalize(modelClass) {
      var result = this._super.apply(this, arguments);
      return _injectLinksForRelationships(modelClass, result);
    }
  });


  function _injectLinksForRelationships(modelClass, result) {
    Ember.get(modelClass, 'relationshipsByName').forEach(function (relationship, name) {
      var urlTemplate = _templateName(relationship.options.urlTemplate, name);
      _injectLinkForRelationship(result, name, urlTemplate);
    });

    return result;
  }

  function _templateName(setting, name) {
    if (setting && typeof setting === 'boolean') {
      return name;
    } else {
      return setting;
    }
  }

  function _injectLinkForRelationship(result, name, urlTemplateOption) {
    if (urlTemplateOption) {
      result.data.relationships[name] = { links: { related: LINK_PREFIX + urlTemplateOption } };
    }
  }
});