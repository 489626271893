define("lodash/_arraySample", ["exports", "lodash/_baseRandom"], function (_exports, _baseRandom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A specialized version of `_.sample` for arrays.
   *
   * @private
   * @param {Array} array The array to sample.
   * @returns {*} Returns the random element.
   */
  function arraySample(array) {
    var length = array.length;
    return length ? array[(0, _baseRandom.default)(0, length - 1)] : undefined;
  }

  var _default = arraySample;
  _exports.default = _default;
});