define("ember-file-upload/components/base-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fileQueue: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'queue')) {
        Ember.setProperties(Ember.get(this, 'queue'), Ember.getProperties(this, 'accept', 'multiple', 'disabled', 'onfileadd'));
      }
    },
    queue: Ember.computed('name', {
      get: function get() {
        var queueName = Ember.get(this, 'name');

        if (queueName != null) {
          var queues = Ember.get(this, 'fileQueue');
          return queues.find(queueName) || queues.create(queueName);
        }
      }
    })
  });

  _exports.default = _default;
});