define("ember-composable-helpers/helpers/chunk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chunk = chunk;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var max = Math.max,
      ceil = Math.ceil;

  function chunk(num, array) {
    var integer = parseInt(num, 10);
    var size = max(integer, 0);
    var length = 0;

    if (Ember.isArray(array)) {
      length = array.length;
    }

    if (!length || size < 1) {
      return [];
    } else {
      var index = 0;
      var resultIndex = -1;
      var result = new Array(ceil(length / size));

      while (index < length) {
        result[++resultIndex] = array.slice(index, index += size);
      }

      return result;
    }
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        num = _ref2[0],
        array = _ref2[1];

    return chunk(num, array);
  });

  _exports.default = _default;
});