define("lodash/negate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** Error message constants. */
  var FUNC_ERROR_TEXT = 'Expected a function';
  /**
   * Creates a function that negates the result of the predicate `func`. The
   * `func` predicate is invoked with the `this` binding and arguments of the
   * created function.
   *
   * @static
   * @memberOf _
   * @since 3.0.0
   * @category Function
   * @param {Function} predicate The predicate to negate.
   * @returns {Function} Returns the new negated function.
   * @example
   *
   * function isEven(n) {
   *   return n % 2 == 0;
   * }
   *
   * _.filter([1, 2, 3, 4, 5, 6], _.negate(isEven));
   * // => [1, 3, 5]
   */

  function negate(predicate) {
    if (typeof predicate != 'function') {
      throw new TypeError(FUNC_ERROR_TEXT);
    }

    return function () {
      var args = arguments;

      switch (args.length) {
        case 0:
          return !predicate.call(this);

        case 1:
          return !predicate.call(this, args[0]);

        case 2:
          return !predicate.call(this, args[0], args[1]);

        case 3:
          return !predicate.call(this, args[0], args[1], args[2]);
      }

      return !predicate.apply(this, args);
    };
  }

  var _default = negate;
  _exports.default = _default;
});