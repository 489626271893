define("lodash/_realNames", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** Used to lookup unminified function names. */
  var realNames = {};
  var _default = realNames;
  _exports.default = _default;
});