define("ember-click-outside/modifier", ["exports", "ember-click-outside/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember._setModifierManager(function () {
    return {
      createModifier: function createModifier(factory, args) {
        return {
          element: null,
          eventHandler: null,
          action: null,
          eventType: null
        };
      },
      installModifier: function installModifier(state, element, args) {
        var _args$positional = _slicedToArray(args.positional, 1),
            action = _args$positional[0];

        var exceptSelector = args.named.exceptSelector;
        var _args$named$eventType = args.named.eventType,
            eventType = _args$named$eventType === void 0 ? 'click' : _args$named$eventType;

        if (action) {
          state.action = action;
          state.element = element;
          state.eventType = eventType;
          state.eventHandler = createHandler(element, action, exceptSelector);
          document.addEventListener(eventType, state.eventHandler);
        }

        if ((0, _utils.ios)()) {
          document.body.style.cursor = 'pointer';
        }
      },
      updateModifier: function updateModifier(state, args) {
        var _args$positional2 = _slicedToArray(args.positional, 1),
            action = _args$positional2[0];

        var exceptSelector = args.named.exceptSelector;
        var _args$named$eventType2 = args.named.eventType,
            eventType = _args$named$eventType2 === void 0 ? 'click' : _args$named$eventType2;

        if (state.action) {
          document.removeEventListener('click', state.eventHandler);
        }

        if (action) {
          state.action = action;
          state.eventType = eventType;
          state.eventHandler = createHandler(state.element, action, exceptSelector);
          document.addEventListener(eventType, state.eventHandler);
        }
      },
      destroyModifier: function destroyModifier(state, element) {
        if (state.action) {
          document.removeEventListener(state.eventType, state.eventHandler);
        }

        if ((0, _utils.ios)()) {
          document.body.style.cursor = '';
        }
      }
    };
  }, function OnClickOutsideModifier() {
    _classCallCheck(this, OnClickOutsideModifier);
  });

  _exports.default = _default;

  var createHandler = function createHandler(element, action, exceptSelector) {
    return function (e) {
      if (exceptSelector && (0, _utils.closest)(e.target, exceptSelector)) {
        return;
      }

      var path = e.path || e.composedPath && e.composedPath();

      if (path) {
        path.includes(element) || action(e);
      } else {
        // Check if the click target still is in the DOM.
        // If not, there is no way to know if it was inside the element or not.
        var isRemoved = !e.target || !(0, _utils.documentOrBodyContains)(e.target); // Check the element is found as a parent of the click target.

        var isInside = element === e.target || element.contains(e.target);

        if (!isRemoved && !isInside) {
          action(e);
        }
      }
    };
  };
});