define("lodash/upperCase", ["exports", "lodash/_createCompounder"], function (_exports, _createCompounder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Converts `string`, as space separated words, to upper case.
   *
   * @static
   * @memberOf _
   * @since 4.0.0
   * @category String
   * @param {string} [string=''] The string to convert.
   * @returns {string} Returns the upper cased string.
   * @example
   *
   * _.upperCase('--foo-bar');
   * // => 'FOO BAR'
   *
   * _.upperCase('fooBar');
   * // => 'FOO BAR'
   *
   * _.upperCase('__foo_bar__');
   * // => 'FOO BAR'
   */
  var upperCase = (0, _createCompounder.default)(function (result, word, index) {
    return result + (index ? ' ' : '') + word.toUpperCase();
  });
  var _default = upperCase;
  _exports.default = _default;
});