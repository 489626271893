define("ember-gestures/recognizers/vertical-pan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    include: [],
    exclude: [],
    eventName: 'pan',
    options: {
      direction: typeof Hammer === 'undefined' ? '' : Hammer.DIRECTION_VERTICAL
    },
    recognizer: 'pan'
  };
  _exports.default = _default;
});