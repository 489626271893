define("lodash/remove", ["exports", "lodash/_baseIteratee", "lodash/_basePullAt"], function (_exports, _baseIteratee, _basePullAt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Removes all elements from `array` that `predicate` returns truthy for
   * and returns an array of the removed elements. The predicate is invoked
   * with three arguments: (value, index, array).
   *
   * **Note:** Unlike `_.filter`, this method mutates `array`. Use `_.pull`
   * to pull elements from an array by value.
   *
   * @static
   * @memberOf _
   * @since 2.0.0
   * @category Array
   * @param {Array} array The array to modify.
   * @param {Function} [predicate=_.identity] The function invoked per iteration.
   * @returns {Array} Returns the new array of removed elements.
   * @example
   *
   * var array = [1, 2, 3, 4];
   * var evens = _.remove(array, function(n) {
   *   return n % 2 == 0;
   * });
   *
   * console.log(array);
   * // => [1, 3]
   *
   * console.log(evens);
   * // => [2, 4]
   */
  function remove(array, predicate) {
    var result = [];

    if (!(array && array.length)) {
      return result;
    }

    var index = -1,
        indexes = [],
        length = array.length;
    predicate = (0, _baseIteratee.default)(predicate, 3);

    while (++index < length) {
      var value = array[index];

      if (predicate(value, index, array)) {
        result.push(value);
        indexes.push(index);
      }
    }

    (0, _basePullAt.default)(array, indexes);
    return result;
  }

  var _default = remove;
  _exports.default = _default;
});