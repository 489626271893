define('ember-text-highlight/-private/env-detection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isSafari = isSafari;
  function isSafari() {
    return (/constructor/i.test(window.HTMLElement) || function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      }(!window['safari'] || typeof safari !== 'undefined' && safari.pushNotification)
    );
  }
});