define("lodash/add", ["exports", "lodash/_createMathOperation"], function (_exports, _createMathOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Adds two numbers.
   *
   * @static
   * @memberOf _
   * @since 3.4.0
   * @category Math
   * @param {number} augend The first number in an addition.
   * @param {number} addend The second number in an addition.
   * @returns {number} Returns the total.
   * @example
   *
   * _.add(6, 4);
   * // => 10
   */
  var add = (0, _createMathOperation.default)(function (augend, addend) {
    return augend + addend;
  }, 0);
  var _default = add;
  _exports.default = _default;
});