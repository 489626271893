define("lodash/_mapCacheHas", ["exports", "lodash/_getMapData"], function (_exports, _getMapData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Checks if a map value for `key` exists.
   *
   * @private
   * @name has
   * @memberOf MapCache
   * @param {string} key The key of the entry to check.
   * @returns {boolean} Returns `true` if an entry for `key` exists, else `false`.
   */
  function mapCacheHas(key) {
    return (0, _getMapData.default)(this, key).has(key);
  }

  var _default = mapCacheHas;
  _exports.default = _default;
});