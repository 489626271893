define("lodash/_baseMean", ["exports", "lodash/_baseSum"], function (_exports, _baseSum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** Used as references for various `Number` constants. */
  var NAN = 0 / 0;
  /**
   * The base implementation of `_.mean` and `_.meanBy` without support for
   * iteratee shorthands.
   *
   * @private
   * @param {Array} array The array to iterate over.
   * @param {Function} iteratee The function invoked per iteration.
   * @returns {number} Returns the mean.
   */

  function baseMean(array, iteratee) {
    var length = array == null ? 0 : array.length;
    return length ? (0, _baseSum.default)(array, iteratee) / length : NAN;
  }

  var _default = baseMean;
  _exports.default = _default;
});