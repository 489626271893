define("ember-file-upload/services/file-queue", ["exports", "ember-file-upload/queue", "ember-file-upload/mixins/with-files"], function (_exports, _queue, _withFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    The file queue service is a global file
    queue that manages all files being uploaded.
  
    This service can be used to query the current
    upload state when a user leaves the app,
    asking them whether they want to cancel
    the remaining uploads.
  
    @class FileQueue
    @extends Ember.Service
   */
  var _default = Ember.Service.extend(_withFiles.default, {
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'queues', Ember.A());
      Ember.set(this, 'files', Ember.A());
    },

    /**
      The list of all files in queues. This automatically gets
      flushed when all the files in the queue have settled.
       Note that files that have failed need to be manually
      removed from the queue. This is so they can be retried
      without resetting the state of the queue, orphaning the
      file from its queue. Upload failures can happen due to a
      timeout or a server response. If you choose to use the
      `abort` method, the file will fail to upload, but will
      be removed from the requeuing proccess, and will be
      considered to be in a settled state.
       @property files
      @type {File[]}
      @default []
     */
    files: null,

    /**
      Returns a queue with the given name
       @method find
      @param {String} name The name of the queue to find
      @return {Queue} The queue or null if it doesn't exist yet.
     */
    find: function find(name) {
      return Ember.get(this, 'queues').findBy('name', name);
    },

    /**
      Create a new queue with the given name.
       @method create
      @param {String} name The name of the queue to create
      @return {Queue} The new queue.
     */
    create: function create(name) {
      (false && !(this.find(name) == null) && Ember.assert("Queue names are required to be unique. \"".concat(name, "\" has already been reserved."), this.find(name) == null));

      var queue = _queue.default.create({
        name: name,
        fileQueue: this
      });

      Ember.get(this, 'queues').push(queue);
      Ember.run.once(this, 'notifyPropertyChange', 'queues');
      return queue;
    }
  });

  _exports.default = _default;
});