define("lodash/_reInterpolate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** Used to match template delimiters. */
  var reInterpolate = /<%=([\s\S]+?)%>/g;
  var _default = reInterpolate;
  _exports.default = _default;
});