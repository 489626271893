define("lodash/isWeakMap", ["exports", "lodash/_getTag", "lodash/isObjectLike"], function (_exports, _getTag, _isObjectLike) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /** `Object#toString` result references. */
  var weakMapTag = '[object WeakMap]';
  /**
   * Checks if `value` is classified as a `WeakMap` object.
   *
   * @static
   * @memberOf _
   * @since 4.3.0
   * @category Lang
   * @param {*} value The value to check.
   * @returns {boolean} Returns `true` if `value` is a weak map, else `false`.
   * @example
   *
   * _.isWeakMap(new WeakMap);
   * // => true
   *
   * _.isWeakMap(new Map);
   * // => false
   */

  function isWeakMap(value) {
    return (0, _isObjectLike.default)(value) && (0, _getTag.default)(value) == weakMapTag;
  }

  var _default = isWeakMap;
  _exports.default = _default;
});