define("lodash/_createInverter", ["exports", "lodash/_baseInverter"], function (_exports, _baseInverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a function like `_.invertBy`.
   *
   * @private
   * @param {Function} setter The function to set accumulator values.
   * @param {Function} toIteratee The function to resolve iteratees.
   * @returns {Function} Returns the new inverter function.
   */
  function createInverter(setter, toIteratee) {
    return function (object, iteratee) {
      return (0, _baseInverter.default)(object, setter, toIteratee(iteratee), {});
    };
  }

  var _default = createInverter;
  _exports.default = _default;
});