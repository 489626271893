define("lodash/_getNative", ["exports", "lodash/_baseIsNative", "lodash/_getValue"], function (_exports, _baseIsNative, _getValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Gets the native function at `key` of `object`.
   *
   * @private
   * @param {Object} object The object to query.
   * @param {string} key The key of the method to get.
   * @returns {*} Returns the function if it's native, else `null`.
   */
  function getNative(object, key) {
    var value = (0, _getValue.default)(object, key);
    return (0, _baseIsNative.default)(value) ? value : null;
  }

  var _default = getNative;
  _exports.default = _default;
});